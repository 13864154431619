<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">排放污水厂管理</h1>
    </div>
    <div slot="extra">
      <a-button style="margin-left: 16px;" icon="plus" @click="handleAdd" type="primary">新增</a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="名称">
              <a-input v-model="searchParams.name" placeholder="请填写排放污水厂名称"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading"
               :pagination="pagination"
               @change="tableChange"
               :rowKey="(record) => record.id">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="添加排放污水厂"
             @ok="handleAddOk"
             @cancel="closeAddModal"
             :visible="addModal"
             type="add">
      <sewage-plant-form ref="addRef" @addSuccess="addSuccess" type="add" @addError="addError"></sewage-plant-form>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改排放污水厂"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading">
      <sewage-plant-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></sewage-plant-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { columns } from './common/common'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import SewagePlantForm from './Form'

  export default {
    name: 'sewagePlantList',
    components: { PageLayout, SewagePlantForm },
    mixins: [entityCRUDWithCopy],
    data () {
      return {
        dateSearch: null,
        entityBaseUrl: SERVICE_URLS.csgz.sewagePlantApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: ''
        },
        initColumns: columns()
      }
    },
    methods: {}
  }
</script>

<style scoped></style>
