<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '排污厂名称不能为空' }] }]" placeholder="请输入排污厂名称"></a-input>
      </a-form-item>
      <a-form-item label="描述信息" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['description', { rules: [{ required: false, message: '描述信息不能为空' }] }]" rows="3" :maxLength="200" placeholder="请输入描述信息，最多输入200字"></a-textarea>
      </a-form-item>
      <a-form-item label="详细位置" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input-search
          @search="openPointModal"
          v-decorator="['location', { rules: [{ required: true, message: '详细位置不能为空' }] }]"
          placeholder="请输入详细位置">
          <a-button icon="environment" slot="enterButton"></a-button>
        </a-input-search>
      </a-form-item>
      <a-form-item label="经度" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['longitude', { rules: [{ required: true, message: '经度不能为空' }] }]" placeholder="请输入经度"></a-input>
      </a-form-item>
      <a-form-item label="纬度" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['latitude', { rules: [{ required: true, message: '纬度不能为空' }] }]" placeholder="请输入纬度"></a-input>
      </a-form-item>
    </a-form>
    <a-modal
      title="地图标记"
      :visible="pointModal"
      width="1200px"
      :maskClosable="false"
      centered
      @ok="handlePointOk"
      @cancel="closePointModal">
      <amap-select-point :type="type" ref="amapSelectPointRef" @pointSuccess="pointSuccess"
                         @pointError="pointError"></amap-select-point>
    </a-modal>
  </a-spin>
</template>

<script>
  import { sewagePlantVO } from './common/sewagePlantVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'
  import AmapSelectPoint from '../gatherspot/AmapSelectPoint'

  export default {
    name: 'SewagePlantForm',
    mixins: [entityCURDCallback],
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { AmapSelectPoint },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.sewagePlantApi,
        initFormItem: sewagePlantVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        /*标记图层*/
        pointModal: false,
        point: {},
      }
    },
    computed: {},
    created () {
    },
    methods: {
      //地图选择位置
      openPointModal () {
        this.pointModal = true
        if (this.type !== 'add') {
          this.$nextTick(() => {
            this.$refs['amapSelectPointRef'].viewLocationInfo(this.formItem.location, this.formItem.longitude, this.formItem.latitude, "")
          })
        } else {
          this.$nextTick(() => {
            this.$refs['amapSelectPointRef'].addLocationInfo("")
          })
        }
      },
      handlePointOk () {
        this.$refs['amapSelectPointRef'].submitInfo()
      },
      closePointModal () {
        this.pointModal = false
      },
      pointSuccess (location, lnglat, name) {
        this.pointModal = false
        this.form.setFieldsValue({
          latitude: lnglat.latitude,
          longitude: lnglat.longitude,
          location: location
        })
        this.formItem.latitude = location
        this.formItem.latitude = lnglat.latitude
        this.formItem.longitude = lnglat.longitude
        this.formItem.locationName = name
      },
      pointError () {
        this.pointModal = false
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          description: this.formItem.description,
          location: this.formItem.location,
          latitude: this.formItem.latitude,
          longitude: this.formItem.longitude,
          sorter: this.formItem.sorter
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.description = values.description
        this.formItem.location = values.location
        this.formItem.latitude = values.latitude
        this.formItem.longitude = values.longitude
        this.formItem.sorter = values.sorter
      }
    }
  }
</script>
