export const sewagePlantVO = function () {
  return {
    id: '',
    name: '',
    description: '',
    location: '',
    latitude: '',
    longitude: '',
    sorter: '',
    state: 0
  }
}